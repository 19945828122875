<template>
  <div class="container">
    <div class="row">
      <!-- main dashboard -->
      <div class="col col-lg-12 col-md-12 col-sm-12">
        <div class="row">
          <div class="col col-lg-3 col-md-3 col-sm-6">
            <div class="requestDetails bg-warning text-dark">
              <h4>TOTAL SCAN</h4>
              <p>{{ totalScans }}</p>
            </div>
          </div>
          <div class="col col-lg-3 col-md-3 col-sm-6">
            <div class="requestDetails bg-info">
              <h4>GOOD SCAN</h4>
              <p>{{ goodScans }}</p>
            </div>
          </div>
          <div class="col col-lg-3 col-md-3 col-sm-6">
            <div class="requestDetails bg-danger">
              <h4>BAD SCAN</h4>
              <p>{{ badScans }}</p>
            </div>
          </div>
          <div class="col col-lg-3 col-md-3 col-sm-6">
            <div class="requestDetails bg-success">
              <h4>SMS VERIFICATION</h4>
              <p>{{ verifiedSms }}</p>
            </div>
          </div>
        </div>

        <!-- Error -->
        <div
          v-show="errorFlag"
          class="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          {{ errorMsg }}
        </div>

        <div class="row">
          <div class="col col-lg-12 col-md-12 col-sm-12">
            <div class="containerRecentReq">
              <h5>Recent Requests</h5>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Row #</th>
                      <th>Name</th>
                      <th>Mobile #</th>
                      <th>Batch #</th>
                      <th>Serial #</th>
                      <th>Location</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="r in scans" v-bind:key="r.id">
                      <td>{{ r.rowNo }}</td>
                      <td>{{r.productName}}</td>
                      <td>{{ r.mobileNo }}</td>
                      <td>{{ r.batchId }}</td>
                      <td>{{ r.serialNo }}</td>
                      <td><a target="scan" :href="r.scanLocUrl" v-if="r.scanLocUrl !== ''">{{r.scanLoc}}</a></td>
                      <td>{{ new Date(r.scanDate).toDateString() }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <ul class="pagination just-center mt-2" v-show="lastPageNoToShow > 1">
              <li class="page-item" :class="index === currentPage ? 'active' : ''" v-for="index in lastPageNoToShow" :key="index" >
                <a v-on:click="getPagedRequest(index)" class="page-link">{{ index }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../style/style.css";
import ChevronApi from "../lib/chevron-api";

export default {
  name: "Dashboard",

  data() {
    return {
      totalRecords: 0,
      totalScans: 0,
      goodScans: 0,
      badScans: 0,
      verifiedSms: 0,
      scans: [],
      errorMsg: "",
      errorFlag: false,
      currentPage: 1,
      recordPerPage: 7,
      startPageNoToShow: 1,
      status: undefined,
      startDate: undefined,
      endDate: undefined
    };
  },

  mounted() {
    this.getPagedRequest(this.currentPage);

    new ChevronApi().getScanSummary().then(summary => {
      // this.total = is set when the data comes in, so we do not set it here
      this.totalScans = summary.goodScans + summary.badScans;
      this.goodScans = summary.goodScans;
      this.badScans = summary.badScans;
      this.verifiedSms = summary.verifiedSms;
    });
  },

  computed: {
    lastPageNoToShow() {
      var count = 10;
      if (this.totalRecords === 0) {
        return 1;
      }
      var last = this.startPageNoToShow + 10;
      var lastCount = last * this.recordPerPage;
      if (lastCount > this.totalRecords) {
        count = Math.ceil(this.totalRecords / this.recordPerPage) - this.startPageNoToShow + 1;
      }
      return count;
    },
  },

  methods: {
    getPagedRequest(index) {
      const api = new ChevronApi();

      api.getLastScans(index - 1, 7)
        .then((response) => {
          this.errorFlag = false;
          this.scans = response.items;
          this.totalRecords = response.totalRecords;
          this.currentPage = index;
          
          var i = (this.currentPage - 1) * 7 + 1;
          for (var r of this.scans) {
            r.rowNo = i++;
            if (r.latitude != null && r.longitude != null) {
              r.scanLoc = `${r.latitude.toFixed(2)},${r.longitude.toFixed(2)}`
              r.scanLocUrl = `https://maps.google.com/?q=${r.latitude},${r.longitude}`
            }
            else {
              r.scanLoc = 'N/A'
              r.scanLocUrl = '';
            }
          }
        })
        .catch((error) => {
          this.errorMsg = error;
          this.errorFlag = true;
        });
    },
 
    requestDetail(id) {
      this.$router.push(`/home/request-detail/${id}`)
    },
  },
};
</script>

<style scoped>
.requestDetails {
  height: 85px;
  padding: 15px 0;
  border: 2px solid darkgray;
  color: snow;
  text-align: center;
}
.requestDetails h4 {
  font-size: 11pt;
}
.containerRecentReq {
  margin-top: 50px;
  border: 2px solid darkgray;
  text-align: left;
  padding: 30px;
}
.inventorySection {
  margin: 50px auto;
  text-align: left;
}
.containerChart {
  border: 2px solid black;
  height: 350px;
  padding-top: 15px;
}
.chart {
  border: 2px solid darkgray;
  height: 320px;
  text-align: center;
}
.chart img {
  width: 100%;
  height: 100%;
}
.th {
  background-color: gray;
}

@media (max-width: 767px) {
  .containerChart {
    border: 2px solid black;
    height: 600px;
    padding-top: 15px;
  }
}
</style>
