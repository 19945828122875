import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import router from './routes'
import store from './store'

createApp(App).use(router).use(store).mount('#app')


//router pkg ==> npm i vue-router@next
//axios pkg ==> npm i axios && import axios from 'axios'
//vuex pkg ==> npm i vuex@next
//veux persist pkg ==> npm install --save vuex-persist