<template>
  <div class="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark" style="width: 280px;">
    <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
      <svg class="bi me-2" width="40" height="32"><use xlink:href="#bootstrap"></use></svg>
      <span class="fs-4"><img src="../image/logo.png" class="img-fluid mx-auto d-block rounded" alt="logo" /></span>
    </a>
    <hr>
    <ul class="nav nav-pills flex-column mb-auto">
      <li>
        <router-link style="text-decoration: none" to="/home/dashboard">
          <a v-on:click="showDashboard" class="nav-link" v-bind:class="dashboardClicked && 'active'" aria-current="page">
            <svg class="bi me-2" width="16" height="16"><use xlink:href="#home"></use></svg>Dashboard
          </a>
        </router-link>
      </li>
      <li>
        <router-link style="text-decoration: none" to="/home/scanLocations">
          <a v-on:click="showScanLocations" class="nav-link" v-bind:class="scanLocationsClicked && 'active'" aria-current="page">
            <svg class="bi me-2" width="16" height="16"><use xlink:href="#speedometer2"></use></svg>Scanned Locations
          </a>
        </router-link>
      </li>
      <li>
        <router-link style="text-decoration: none" to="/home/scanResults">
          <a v-on:click="showScanResults" class="nav-link" v-bind:class="scanResultsClicked && 'active'" aria-current="page">
            <svg class="bi me-2" width="16" height="16"><use xlink:href="#speedometer2"></use></svg>Scanned Results
          </a>
        </router-link>
      </li>
    </ul>
    <hr>
    <div class="dropdown">
      <a v-on:click="logout" title="Admin" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="https://github.com/mdo.png" alt="" width="32" height="32" class="rounded-circle me-2">
        <strong v-on:click="logout">Logout</strong>
      </a>
    </div>
  </div>
  <div class="container">
    <div class="col col-lg-12 col-md-12 col-sm-12 mt-5">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import "../style/style.css";
import store from "../store";

export default {
  name: "Home",
  
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Chevron Track and Trace',
  },

  data() {
    return {
      dashboardClicked: true,
      scanLocationsClicked: false,
      scanResultsClicked: false,
    };
  },
  methods: {
    logout() {
      store.state.token = "";
      this.$router.push({ name: "Login" });
    },
    showDashboard() {
      this.dashboardClicked = true
      this.scanLocationsClicked = false
      this.scanResultsClicked = false
    },
    showScanLocations() {
      this.dashboardClicked = false
      this.scanLocationsClicked = true
      this.scanResultsClicked = false
    },
    showScanResults() {
      this.dashboardClicked = false
      this.scanLocationsClicked = false
      this.scanResultsClicked = true
    },
  },
};
</script>
