import axios from "axios";
import store from "../store.js";

class ApiException extends Error {
  constructor(code, message) {
    super();
    this.code = code;
    this.message = message;
  }
}

function getResponseData(response) {
  if (response.data !== undefined) {
    // the old style API had a data member inside its response
    // with a message and status field in body to indicate success
    // newer style API used http status code not the body for this
    if (response.data.data !== undefined && 
      response.data.message !== undefined && 
      response.data.transactionResult !== undefined) {
        // it definitely is an old style API
        return response.data.data;
      }
    else {
      return response.data;
    }
  }

  return response;
}

function throwError(error) {
  if (error.response) {
    if (error.response.data !== undefined)
      throw new ApiException(error.response.status, error.response.data.message);
    else
      throw new ApiException(error.response.status, `Error occurred on the server. Error code: ${error.response.staus}`);
  } else if (error.request) {
    throw new ApiException(404, "Problem with request! 404 Not found");
  } else {
    throw new ApiException(0, "The server could not be reached");
  }
}

class ChevronApi {
  constructor() {
    var host;
    if (window.location.host.startsWith('localhost'))
      host = "http://localhost:8011";
    else
      host = "https://apichevron.atksrv.net";

    this.base = `${host}/api`
    this.healthUrl = `${host}/health`
  }

  getBase() {
    return this.base;
  }

  post(url, body) {
    return axios.post(`${this.base}${url}`, body, {
        headers: {
          "x-auth-token": store.state.token,
        },
      })
      .then(getResponseData)
      .catch(throwError);
  }

  get(url) {
    const token = store.state.token;
    const headers = {
      "x-auth-token": token,
    };

    return axios.get(`${this.base}${url}`, 
      {
        headers
      })
      .then(getResponseData)
      .catch(throwError);
  }

  login(email, password) {
    return this.post('/token/get', {
        emailAddress: email,
        password: password,
        code: "chevron",
    })
  }

  serverVersion() {
    return axios.get(`${this.healthUrl}/version`) 
      .then(getResponseData)
      .catch(throwError);
  }

  // possible mapped keys that can be sent to the server include
  // startDate, endData, product and batch
  getLastScans(currentPage, recordsPerPage, extraParams) {
    let url = `/secure/chevron/scanInfo?pageNo=${currentPage}`;

    let queryParams = '';
    if (extraParams !== undefined) {
      for (const p of Object.keys(extraParams)) {
        // todo: urlencode
        queryParams += `&${p}=${extraParams[p]}`;
      }
    }

    url += `&recordsPerPage=${recordsPerPage}` + queryParams;
    return this.get(url);
  }
  
  getScanSummary() {
    let url = `/secure/chevron/scanInfoSummary`;
    return this.get(url);
  }

  getProducts() {
    let url = `/secure/chevron/products`;
    return this.get(url);
  }
}

export default ChevronApi;
