<template>
  <div class="container">
    <h3>Scanned Locations:</h3>
    <div class="row">
      <div class="col col-lg-4 col-md-4 col-sm-12">
        <h6>Date From:</h6>
        <input type="date" v-model="startDate" />
      </div>
      <div class="col col-lg-4 col-md-4 col-sm-12">
        <h6>Date To:</h6>
        <input type="date" v-model="endDate" />
      </div>
      <div class="col col-lg-4 col-md-4 col-sm-12">
        <h6>Product:</h6>
        <label for="status"></label>

        <select v-model="searchProduct">
          <option v-for="p in products" v-bind:key="p.id" v-bind:value="p.id">{{p.title}}</option>
        </select>
      </div>

      <div class="row mt-2">
        <div class="col col-lg-4 col-md-4 col-sm-12">
        <h6>Batch #:</h6>
        <input type="text" v-model="searchBatch" />
        </div>
        <div class="col col-lg-4 col-md-4 col-sm-12"></div>
        <div class="col col-lg-4 col-md-4 col-sm-12">
          <button v-on:click="filterRequest" class="btn btn-primary searchBtn">
            Search
          </button>
        </div>
      </div>

      <!-- Error -->
      <div
        v-show="errorFlag"
        class="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
        {{ errorMsg }}
      </div>

      <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12">

     <div id="map"></div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css"

import ChevronApi from "../lib/chevron-api"
import "../style/style.css"

export default {
  name: "RequestList",

  components: {
  },

  data() {
    return {
      products: [],
      startDate: "",
      endDate: "",
      status: undefined,
      totalRequests: "0",
      errorFlag: false,
      errorMsg: "",
      filterError: "Invalid Filter Error",
      searchProduct: '',
      searchBatch: '',
      scans: [],
      operationHeading: 'Results',
      zoom: 2,
      coordinates: [50, 50],
    };
  },

  mounted() {
    let link = document.createElement('link')
    link.setAttribute('rel', 'stylesheet')
    link.setAttribute('href', 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css')
    link.setAttribute('integrity', 'sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A==')
    link.setAttribute('crossorigin','')
    document.head.appendChild(link)

    let script = document.createElement('script')
    script.setAttribute('src', 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.js')
    script.setAttribute('integrity', 'sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA==')
    script.setAttribute('crossorigin','')
    script.setAttribute('onload','createMap()')
    document.head.appendChild(script)

    const end = document.createElement('script');
    const mapBody = document.createTextNode(`
    function createMap() {
      var map = L.map('map').setView([24.86, 67.00], 13);
      var tiles = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw', {
        maxZoom: 18,
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ' +
          'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1
      }).addTo(map);

      window.map = map;
      window.markers = L.layerGroup().addTo(map)
    }

  window.addPoints = function(scans) {
    //window.markers.addLayer(L.marker([24.86, 67.00]).addTo(window.map);
    //window.markers.addLayer(L.marker([24.86, 67.00]))

    window.markers.clearLayers();
    for (let i = 0; i < scans.length; i++) {
      const s = scans[i]
      console.log(s)
      L.marker([s.latitude, s.longitude]).addTo(window.markers)
    }
  }
  `);
  end.appendChild(mapBody);

  document.body.appendChild(end);

    var today = new Date()
    this.endDate = today.toISOString().split("T")[0]
    // start date should be one month before today
    today.setMonth(today.getMonth() - 1)
    this.startDate = today.toISOString().split("T")[0]

    this.getProductList()
  },

  computed: {
    lastPageNoToShow() {
      var count = 10;
      if (this.totalRequests === 0) {
        return 1;
      }
      var last = this.startPageNoToShow + 10;
      var lastCount = last * this.recordPerPage;
      if (lastCount > this.totalRequests) {
        count = Math.ceil(this.totalRequests / this.recordPerPage) - this.startPageNoToShow + 1;
      }
      return count;
    },
  },

  methods: {
    getPagedRequest(index) {
      this.currentPage = index;
      this.filterRequest();
    },
 
    getProductList() {
      const api = new ChevronApi();

      api.getProducts()
        .then((response) => {
          this.errorFlag = false;
          this.products = response;
        })
        .catch((error) => {
          this.errorMsg = error;
          this.errorFlag = true;
        });
    },

    filterRequest() {
      if (this.endDate < this.startDate) {
        this.errorMsg = this.filterError
        this.errorFlag = true
      } 
      else {
        let params = {startDate: this.startDate, endDate: this.endDate}
        if (this.searchBatch !== '') params['batch'] = this.searchBatch
        if (this.searchProduct !== '') params['product'] = this.searchProduct

        console.log(params);

        this.operationHeading = 'Fetching...'
        const api = new ChevronApi();

        // page # has to be 0 based
        api.getLastScans(0, 4000, params)
        .then((response) => {
          this.operationHeading = 'Results'
          this.errorFlag = false;

          const scans = response.items;
          const markers = []
          for (var r of scans) {
            if (r.latitude != null && r.longitude != null) {
              markers.push( {
                latitude: r.latitude,
                longitude: r.longitude
              })
            }
          }

          window.addPoints(markers);
        })
        .catch((error) => {
          this.operationHeading = 'Results'

          this.errorMsg = error;
          this.errorFlag = true;
        });
      }
    },
  },
};
</script>

<style scoped>
select {
  width: 150px;
}
.searchBtn {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 95px;
}
.recentReq {
  border: 1px solid black;
  color: gray;
  padding: 20px;
  margin-bottom: 30px;
}
.setting {
  margin: 0;
  padding: 0;
  width: 50%;
  margin-right: 0%;
}
.setting img {
  width: 100%;
  cursor: pointer;
}
.listTbl {
  margin-top: 0px;
}

.abc {
  background-color: greenyellow;
}

@media (max-width: 1200px) {
  .setting {
    width: 70%;
  }
}
@media (max-width: 991px) {
  .setting {
    width: 150%;
  }
  input,
  select {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .setting {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .setting {
    width: 20%;
  }
}

h3 {
  font-size: 1.1em;
  font-weight: bold;
}

#map { 
  height: 60vh; 
}

</style>
