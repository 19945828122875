<template>
  <div class="container">
    <h3>Scanned Results:</h3>
    <div class="row">
      <div class="col col-lg-4 col-md-4 col-sm-12">
        <h6>Date From:</h6>
        <input type="date" v-model="startDate" />
      </div>
      <div class="col col-lg-4 col-md-4 col-sm-12">
        <h6>Date To:</h6>
        <input type="date" v-model="endDate" />
      </div>
      <div class="col col-lg-4 col-md-4 col-sm-12">
        <h6>Product:</h6>
        <label for="status"></label>

        <select v-model="searchProduct">
          <option v-for="p in products" v-bind:key="p.id" v-bind:value="p.id">{{p.title}}</option>
        </select>
      </div>

      <div class="row mt-2">
        <div class="col col-lg-4 col-md-4 col-sm-12">
        <h6>Batch #:</h6>
        <input type="text" v-model="searchBatch" />
        </div>
        <div class="col col-lg-4 col-md-4 col-sm-12"></div>
        <div class="col col-lg-4 col-md-4 col-sm-12">
          <button v-on:click="filterRequest" class="btn btn-primary searchBtn">Search</button>
        </div>
      </div>

      <!-- Error -->
      <div v-show="errorFlag" class="alert alert-danger alert-dismissible fade show" role="alert">{{ errorMsg }}</div>

      <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12">
          <div class="recentReq row">
            <div class="col col-lg-11 col-md-11 col-sm-11">
              <h5>{{operationHeading}}</h5>
            </div>
            <div class="col col-lg-12 col-md-12 col-sm-12 listTbl">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Row #</th>
                      <th>Name</th>
                      <th>Mobile #</th>
                      <th>Batch #</th>
                      <th>Serial #</th>
                      <th>Location</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="r in scans" v-bind:key="r.id">
                      <td>{{ r.rowNo }}</td>
                      <td align="left">{{r.productName}}</td>
                      <td>{{ r.mobileNo }}</td>
                      <td>{{ r.batchId }}</td>
                      <td>{{ r.serialNo }}</td>
                      <td><a target="scan" :href="r.scanLocUrl" v-if="r.scanLocUrl !== ''">{{r.scanLoc}}</a></td>
                      <td>{{ new Date(r.scanDate).toDateString() }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

            <ul class="pagination just-center" v-show="lastPageNoToShow > 1">
              <li class="page-item" :class="index === currentPage ? 'active' : ''" v-for="index in lastPageNoToShow" :key="index" >
                <a v-on:click="getPagedRequest(index)" class="page-link">{{ index }}</a>
              </li>
            </ul>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronApi from "../lib/chevron-api"
import "../style/style.css"

export default {
  name: "RequestList",

  data() {
    return {
      totalRecords: 0,
      poRequests: [],
      startDate: "",
      endDate: "",
      status: undefined,
      totalRequests: "0",
      currentPage: 1,
      errorFlag: false,
      errorMsg: "",
      filterError: "Invalid Filter Error",
      recordPerPage: 7,
      startPageNoToShow: 1,
      products: [],
      searchProduct: '',
      searchBatch: '',
      scans: [],
      operationHeading: 'Results',
    };
  },

  mounted() {
    var today = new Date()
    this.endDate = today.toISOString().split("T")[0]
    // start date should be one month before today
    today.setMonth(today.getMonth() - 1)
    this.startDate = today.toISOString().split("T")[0]

    this.getProductList()
  },

  computed: {
    lastPageNoToShow() {
      var count = 10;
      if (this.totalRecords === 0) {
        return 1;
      }
      var last = this.startPageNoToShow + 10;
      var lastCount = last * this.recordPerPage;
      if (lastCount > this.totalRecords) {
        count = Math.ceil(this.totalRecords / this.recordPerPage) - this.startPageNoToShow + 1;
      }
      return count;
    },
  },

  methods: {
    getPagedRequest(index) {
      this.currentPage = index;
      this.filterRequest();
    },
 
    getProductList() {
      const api = new ChevronApi();
      api.getProducts()
        .then((response) => {
          this.errorFlag = false;
          this.products = response;
        })
        .catch((error) => {
          this.errorMsg = error;
          this.errorFlag = true;
        });
    },
    
    // todo: the following code has to be a shared component to be shared between dashboard
    // and here but for now we need to copy paste as we are short on time
    filterRequest() {
      if (this.endDate < this.startDate) {
        this.errorMsg = this.filterError
        this.errorFlag = true
      } 
      else {
        let params = {startDate: this.startDate, endDate: this.endDate}
        if (this.searchBatch !== '') params['batch'] = this.searchBatch
        if (this.searchProduct !== '') params['product'] = this.searchProduct

        console.log(params);

        this.operationHeading = 'Fetching...'
        const api = new ChevronApi();

        // page # has to be 0 based
        api.getLastScans(this.currentPage - 1, 7, params)
        .then((response) => {
          this.operationHeading = 'Results'

          this.errorFlag = false;
          this.scans = response.items;
          this.totalRecords = response.totalRecords;
          
          var i = (this.currentPage - 1) * 7 + 1;
          for (var r of this.scans) {
            r.rowNo = i++;
            if (r.latitude != null && r.longitude != null) {
              r.scanLoc = `${r.latitude.toFixed(2)},${r.longitude.toFixed(2)}`
              r.scanLocUrl = `https://maps.google.com/?q=${r.latitude},${r.longitude}`
            }
            else {
              r.scanLoc = 'N/A'
              r.scanLocUrl = '';
            }
          }
        })
        .catch((error) => {
          this.operationHeading = 'Results'

          this.errorMsg = error;
          this.errorFlag = true;
        });
      }
    },
  },
};
</script>

<style scoped>
select {
  width: 150px;
}
.searchBtn {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 95px;
}
.recentReq {
  border: 1px solid black;
  color: gray;
  padding: 20px;
  margin-bottom: 30px;
}
.setting {
  margin: 0;
  padding: 0;
  width: 50%;
  margin-right: 0%;
}
.setting img {
  width: 100%;
  cursor: pointer;
}
.listTbl {
  margin-top: 0px;
}

.abc {
  background-color: greenyellow;
}

@media (max-width: 1200px) {
  .setting {
    width: 70%;
  }
}
@media (max-width: 991px) {
  .setting {
    width: 150%;
  }
  input,
  select {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .setting {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .setting {
    width: 20%;
  }
}

h3 {
  font-size: 1.1em;
  font-weight: bold;
}

</style>
