import { createWebHistory, createRouter } from 'vue-router';
import Login from './components/Login.vue';
import Home from './components/Home.vue';
import Dashboard from './components/Dashboard.vue';
import ScanResults from './components/ScanResults.vue';
import ScanLocations from './components/ScanLocations.vue';

const routes = [
    {
        name: 'Login',
        path: '/',
        component: Login
    },
    {
        name: 'Home',
        path: '/home',
        component: Home,
        children: [
            {
                path: '/home/dashboard',
                component: Dashboard
            },
            {
                path: '/home/scanLocations',
                component: ScanLocations,
            },
            {
                path: '/home/scanResults',
                component: ScanResults,
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;