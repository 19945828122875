<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-lg-4 col-md-2"></div>
      <div class="card col-lg-4 col-md-8">
        <div class="card-header" style="background: transparent"><img src="../image/logo.png" class="img-fluid mx-auto d-block rounded" alt="Logo" /></div>
        <div class="card-body">
          <form>
            <div class="mb-3 mt-3">
              <label for="" class="form-label">Username:</label>
              <input type="test" class="form-control" placeholder="Username or email" v-model="username" required />
            </div>
            <div class="mb-3">
              <label for="" class="form-label">Password:</label>
              <input class="form-control" type="password" name="password" autocomplete="off" placeholder="Password" v-model="password" required />
              <p class="error" v-show="error">{{ errorMsg }}</p>
            </div>
            <button type="button" v-on:click="loginApiCall" class="btn btn-primary">Submit</button>
          </form>
        </div>
        <div class="card-footer " style="background: transparent">
          <p class="text-end">Server Version: {{ version }}</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-2"></div>
    </div>

  </div>
</template>

<script>
import ChevronApi from "../lib/chevron-api";
import "../style/style.css";
import store from "../store.js";

export default {
  name: "Login",

  data() {
    return {
      username: '',
      password: '',
      error: false,
      errorMsg: "",
      version: "",
    };
  },

  mounted() {
    const api = new ChevronApi()
    api.serverVersion()
      .then((response) => {
        this.version = `${response.server}`
      })
      .catch((error) => {
        this.errorFlag = true;
        this.errorMsg = error;
      })
  },

  methods: {
    loginApiCall() {
      const api = new ChevronApi();
      api
        .login(this.username, this.password)
        .then((response) => {
          store.commit("setToken", response.token);
          this.redirect();
        })
        .catch((error) => {
          this.errorMsg = error.message;
          this.error = true;
        });
    },

    redirect() {
      if (store.state.token !== "") {
        this.$router.push("/home/dashboard");
      }
    },
  },
};
</script>
